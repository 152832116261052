<template>
    <div class="page-wrapper">
        <section class="hero_block">
            <div class="data">
                <h1 class="title" data-inview="fadeInUp" data-delay="200"><span v-html="contentIsLoaded ? content.heroSections.titre : ''"></span></h1>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="contentIsLoaded ? content.heroSections.texte : ''"></span>
                </p>
                <a
                    :href="contentIsLoaded ? content.heroSections.boutonUrl[$route.meta.lang] : ''"
                    target="_blank"
                    class="cta regular-text"
                    data-inview="fadeInUp"
                    data-delay="400"
                    ><span v-html="contentIsLoaded ? content.heroSections.bouton.customText : ''"></span></a
                >
                <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
            </div>
            <div class="hero_img" data-inview="slideLeft" data-delay="200">
                <img
                    :src="contentIsLoaded ? content.heroSections.image.url : ''"
                    :alt="contentIsLoaded ? content.heroSections.image.titre : ''"
                />
            </div>
            <img src="@/assets/img/birds-hero.svg" alt="birds" class="birds_hero" />
        </section>

        <section class="event_infos" v-if="contentIsLoaded && content.informationsEvenements.adresse">
            <a
                data-inview="fadeInUp"
                data-delay="200"
                :href="'https://www.google.com/maps/place/' + content.informationsEvenements.adresse.replace('<br />', ' ')"
                target="_blank"
                class="box"
            >
                <img src="@/assets/img/icon-map.svg" alt="Icon map" />
                <address class="regular-text">
                    <span v-html="content.informationsEvenements.adresse"></span>
                </address>
            </a>
            <a data-inview="fadeInUp" data-delay="300" href="#" class="box">
                <img src="@/assets/img/icon-date.svg" alt="Icon date" />
                <p class="regular-text">{{ content.informationsEvenements.date }}</p>
            </a>
            <a data-inview="fadeInUp" data-delay="400" href="#" class="box">
                <img src="@/assets/img/icon-time-2.svg" alt="Icon time" />
                <p class="regular-text">{{ content.informationsEvenements.heure }}</p>
            </a>
        </section>

        <section
            class="text_img" :class="{ 'switch': i % 2 }"
            v-for="(item, i) in splitImageTexte"
            :key="i"
        >
            <div class="data">
                <h3 class="title medium" data-inview="fadeInUp" data-delay="200">
                    <span v-html="item.titre"></span>
                </h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="item.texte"></span>
                </p>
                <a
                    :href="item.boutonUrl[$route.meta.lang]"
                    target="_blank"
                    class="cta regular-text"
                    data-inview="fadeInUp"
                    data-delay="400"
                    >{{ item.bouton.customText }}</a
                >
            </div>
            <div class="img" :data-inview="i % 2 ? 'slideRight' : 'slideLeft'" data-delay="200">
                <img :src="item.image.url" :alt="item.image.titre" />
            </div>
            <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
        </section>

        <section class="text_block mt" v-if="contentIsLoaded && content.blockInformations1.titre">
            <h2 class="title medium" data-inview="fadeInUp" data-delay="200">{{ content.blockInformations1.titre }}</h2>
            <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                <span v-html="content.blockInformations1.texte"></span>
            </p>
            <a :href="content.blockInformations1.boutonUrl[$route.meta.lang]" class="cta regular-text" data-inview="fadeInUp" data-delay="400"
                >{{ content.blockInformations1.bouton.customText }}</a
            >
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'
// import Flickity from 'flickity'

export default {
    name: 'Evenement',

    data() {
        return {
            // teamCarousel: null,
        }
    },

    methods: {
        toogleNavBarStatus() {
            const st = document.documentElement.scrollTop

            if (!document.querySelector('.hero_block')) {
                return
            }

            if (st >= 75) {
                document.querySelector('.hero_block').classList.add('remove_margin_top')
            } else {
                document.querySelector('.hero_block').classList.remove('remove_margin_top')
            }
        },
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        // this.teamCarousel = new Flickity(document.querySelector('.main-carousel'), {
                        //     wrapAround: true,
                        //     imagesLoaded: true,
                        //     prevNextButtons: true,
                        //     pageDots: false,
                        // })

                        this.toogleNavBarStatus()
                        window.addEventListener('scroll', () => {
                            this.toogleNavBarStatus()
                        })
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        splitImageTexte() {
            if (!this.contentIsLoaded) {
                return []
            }
            return this.content.splitImageTexte
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>
